import React from 'react';
import "../style/banner.css";
import { Banners, SmallFooter } from '../components';


const Banner = () => {
    return (
        <>
            <Banners />
            <SmallFooter />
        </>
    )
}

export default Banner