import React from 'react'

const Error = () => {
    return (
        <div className='loading__main'>
            Something went wrong
        </div>
    )
}

export default Error