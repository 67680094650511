import React from 'react'

const SmallFooter = () => {
    return (
        <div className='small__footer'>
            <p>#Hello-World Admin</p>
        </div>
    )
}

export default SmallFooter